import { BaseModule, Constructor, View } from 'outpost';
import { LoginModule } from './modules/login-module.ts';
import { WorldModule } from './modules/world-module.ts';
import { DirectionModule } from './modules/direction-module.ts';
import { GAME_EVENT_CONSTRUCTORS } from './utils/game-event.ts';
import { Ship } from './ship/ship.ts';
import { ShipComponent } from './ship/ship-component.ts';
import { LogoutModule } from './modules/logout-module.ts';
import { RotationModule } from './modules/rotation-module.ts';
import { HudModule } from './modules/hud-module.ts';
import { LeaderboardScene } from './scenes/leaderboard-scene.ts';
import { PlugModule } from './modules/plug-module.ts';
import { ShipComponentSlot } from './ship/ship-component-slot.ts';
import { SoundModule } from './modules/sound-module.ts';
import { Crystal } from './crystal/crystal.ts';
import { Projectile } from './projectile/projectile.ts';

const SERIALIZABLE_CONSTRUCTORS: Constructor[] = [
    ...GAME_EVENT_CONSTRUCTORS,
    Ship, ShipComponent, ShipComponentSlot, Crystal, Projectile
]

export class SnuGame extends BaseModule {
    serializableConstructors: Constructor[] = SERIALIZABLE_CONSTRUCTORS;
    modules = {
        login: new LoginModule(this),
        logout: new LogoutModule(this),
        world: new WorldModule(this),
        direction: new DirectionModule(this),
        rotation: new RotationModule(this),
        plug: new PlugModule(this),
        hud: new HudModule(this),
        leaderboardScene: new LeaderboardScene(this),
        sound: new SoundModule(this),
    };

    get world() {
        return this.modules.world;
    }

    render(view: View): void {

    }
}
globalThis.ALL_FUNCTIONS.push(SnuGame);