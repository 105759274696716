import { ShipComponent } from './ship-component.ts';
import { SHIP_DIRECTION_MULTIPLIER, ShipDirection } from './ship-direction.ts';
import { SHIP_ROTATION_MULTIPLIER, ShipRotation } from './ship-rotation.ts';
import { SHIP_COMPONENT_SIZE, SYN_TIER_FACTOR, rng } from '../constants.ts';
import { Renderable, Point, View, ModuleApi, angleDif, clampAngle, Vector, selectClosestItem } from 'outpost';
import { WorldModule } from '../modules/world-module.ts';
import { SLOT_TO_ANGLE } from './ship-component-slot.ts';
import { GameRoom } from '../game-room.ts';
import { getSyn } from '../modules/hud-module.ts';

export type ShipParams = {
    playerName: string;
    playerId: string
}

export class Ship implements Renderable {
    playerName: string;
    playerId: string;
    components: ShipComponent[] = [];
    position: Point = Point.zero();
    angle: number = 0;
    targetAngle: number = this.angle;
    direction: ShipDirection = 'none';
    rotation: ShipRotation = 'none';
    movementSpeed: number = 300;
    rotationSpeed: number = 10;

    // Stats.
    crystalCount: number = 0
    crystalDestroy: number = 0

    constructor(params: ShipParams) {
        this.playerName = params.playerName;
        this.components.push(new ShipComponent({ kind: 'core', ship: this, position: this.position, id: rng.id() }));
        this.playerId = params.playerId;
    }

    render(view: View): void {
        for (let component of this.components) {
            view.renderChild(component);
        }

        let textRect = view.ui.getView(this.components[0]).getRect()
            .getTopNeighbor(20, SHIP_COMPONENT_SIZE * 2 / 3)
            .scaleWidth(6)

        view.paint({
            ...textRect,
            text: this.playerName,
            textColor: 'white',
        })
    }

    setDirection(direction: ShipDirection) {
        this.direction = direction;
    }

    setRotation(rotation: ShipRotation, targetAngle: number) {
        this.rotation = rotation;
        this.targetAngle = targetAngle;
    }

    update(api: ModuleApi, room: GameRoom, elapsedSecs: number) {
        let prevAngle = this.angle;
        let prevPosition = this.position;
        let prevDeltaAngle = angleDif(prevAngle, this.targetAngle);
        let additionalAngle = SHIP_ROTATION_MULTIPLIER[this.rotation] * this.rotationSpeed * elapsedSecs;
        let nextAngle = clampAngle(prevAngle + additionalAngle);
        let nextDeltaAngle = angleDif(nextAngle, this.targetAngle);

        if (Math.sign(prevDeltaAngle) !== Math.sign(nextDeltaAngle)) {
            nextAngle = this.targetAngle;
            additionalAngle = angleDif(prevAngle, nextAngle);
            this.rotation = 'none';
        }

        this.angle = nextAngle;

        for (let component of this.components) {
            component.angle += additionalAngle;
            component.position = component.position.rotate(additionalAngle, prevPosition);
        }

        let m = SHIP_DIRECTION_MULTIPLIER[this.direction];
        let additionalPosition = new Vector(m, 0).rotate(this.angle).mult(this.movementSpeed * elapsedSecs);
        this.position = this.position.add(additionalPosition);

        for (let component of this.components) {
            component.position = component.position.add(additionalPosition);
        }

        this.components.forEach(component => component.update(api, room, elapsedSecs))
    }

    plugComponent(floatingComponent: ShipComponent): boolean {
        let shipComponent = selectClosestItem(this.components, shipComponent => {
            if (!shipComponent.slots.some(slot => slot.canBePlugged())) {
                return false;
            }

            return shipComponent.position.getDistanceTo(floatingComponent.position);
        });

        if (!shipComponent) {
            return false;
        }

        let shipSlot = selectClosestItem(shipComponent.slots, slot => {
            return slot.canBePlugged() && slot.getPosition().getDistanceTo(floatingComponent.position);
        });

        if (!shipSlot) {
            return false;
        }

        let shipSlotPosition = shipSlot.getPosition();
        let floatingSlot = selectClosestItem(floatingComponent.slots, slot => {
            return slot.canBePlugged() && slot.getPosition().getDistanceTo(shipSlotPosition);
        });

        if (!floatingSlot) {
            return false;
        }

        shipSlot.plugged = floatingComponent;
        floatingSlot.plugged = shipComponent;

        floatingComponent.angle = clampAngle(shipComponent.angle + SLOT_TO_ANGLE[shipSlot.anchor] - SLOT_TO_ANGLE[floatingSlot.anchor] + Math.PI);
        floatingComponent.ship = this;
        floatingComponent.position = shipComponent.position.add(Vector.from([SHIP_COMPONENT_SIZE, 0]).rotate(SLOT_TO_ANGLE[shipSlot.anchor] + shipComponent.angle));

        this.components.push(floatingComponent);

        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(Ship);