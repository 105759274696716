import { ModuleApi, Point, Renderable,  Vector, View, } from 'outpost';
import { GameEvent } from '../utils/game-event.ts';
import { CrystalHpBar } from './crystal-hp-bar.ts';
import { SHIP_COMPONENT_SIZE } from '../constants.ts';

export const CrystalDestroyEvent = GameEvent(class CrystalDestroyEvent {
    roomId: string = '';
    id: string = '';
})

export const CrystalHitEvent = GameEvent(class CrystalHitEvent {
    roomId: string = '';
    id: string = '';
    damage: number = 0
    projectileId: string = '';
})

export type CrystalParams = {
    id: string;
    position: Point;
    angle: number;
    hp: number;
    radius: number;
    scoreValue: number;
    kind: 'small' | 'medium' | 'big'
}

export const makeLittleCrystalParams = (params: Omit<CrystalParams, 'hp' | 'radius'| 'scoreValue' | 'kind'>): CrystalParams => ({
    ...params,
    hp: 15,
    scoreValue: 1,
    radius: SHIP_COMPONENT_SIZE,
    kind: 'small',
})

export const makeMediumCrystalParams = (params: Omit<CrystalParams, 'hp' | 'radius'| 'scoreValue'| 'kind'>): CrystalParams =>({
    ...params,
    hp: 25,
    scoreValue: 2,
    radius: SHIP_COMPONENT_SIZE * 2,
    kind: 'medium',
})

export const makeBigCrystalParams = (params: Omit<CrystalParams, 'hp' | 'radius'| 'scoreValue'| 'kind'>): CrystalParams => ({
    ...params,
    hp: 35,
    scoreValue: 5,
    radius: SHIP_COMPONENT_SIZE * 4,
    kind: 'big',
})

export class Crystal implements Renderable {
    id: string = ''
    position: Point = Point.zero();
    angle: number = 0;
    hp: number = 3
    hpMax: number = 3
    radius: number = 1
    scoreValue: number = 5
    kind: 'small'|'medium'|'big' = 'small'
    scale: number = 1

    hpBar: CrystalHpBar | null = null

    constructor(params: CrystalParams) {
        this.id = params.id;
        this.kind = params.kind;
        this.position = params.position;
        this.angle = params.angle;
        this.hp = params.hp;
        this.hpMax = params.hp;
        this.radius = params.radius;
        this.scoreValue = params.scoreValue;
    }

    render(view: View): void {

        view.paint({
            ...this.position,
            width: this.radius * 2,
            height: this.radius * 2,
            borderRadius: this.radius,
            imageScale: this.scale,
            imageUrl: `/sprites/crystal_${this.kind}.png`,
        });
    
        if (this.hpBar) {
            view.renderChild(this.hpBar)
        }
    }

    update(api: ModuleApi, elapsedSecs: number) {
        if (/*this.hp < this.hpMax &&*/ !this.hpBar) {
            this.hpBar = new CrystalHpBar(this);
        }

        if (this.scale < 1) {
            this.scale += elapsedSecs
        } else {
            this.scale = 1
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Crystal);