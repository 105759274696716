import { Ship } from '../ship/ship.js';
import { ShipComponentHitEvent, ShipComponentShootEvent } from '../ship/ship-component.js';
import { GLOBAL_ROOM_ID } from '../constants.js';
import { SnuModule } from './snu-module.js';
import { ProjectileDestroyEvent } from '../projectile/projectile.js';
import { Clock, ModuleApi, View, sleep } from 'outpost';
import { CrystalDestroyEvent, CrystalHitEvent } from '../crystal/crystal.js';
import { GameRoom, GameRoomInitEvent, GameRoomRespawnEvent, GameRoomUpdateEvent } from '../game-room.js';

export class WorldModule extends SnuModule {
    clock: Clock = new Clock();
    rooms: Map<string, GameRoom> = new Map();
    playerToRoom: Map<string, GameRoom> = new Map();

    getGlobalRoom(): GameRoom | undefined {
        return this.rooms.values().next().value;
    }

    getGlobalRoomPlayerIds(): string[] {
        return this.rooms.values().next().value.getPlayerIds();
    }

    getPlayer(playerId: string) {
        return this.playerToRoom.get(playerId)?.players.get(playerId);
    }

    async onStart(api: ModuleApi): Promise<void> {
        let room = new GameRoom({
            id: GLOBAL_ROOM_ID
        });
        this.rooms.set(GLOBAL_ROOM_ID, room);
    }

    async runDaemon(api: ModuleApi) {
        await sleep(20);

        for (let room of this.rooms.values()) {
            api.emitEvent({
                event: new GameRoomUpdateEvent({ roomId: room.id, elapsedSec: this.clock.tick() / 1000 }),
                emitToPlayers: room.getPlayerIds(),
                emitToServer: true
            });
        }
    }

    async onPlayerConnected(api: ModuleApi): Promise<void> {
        let room = this.getGlobalRoom()!;

        api.emitEvent({
            event: room.getInitEvent(),
            emitToPlayers: [api.playerId],
            emitToServer: false
        });
    }

    async onEvent(api: ModuleApi) {
        let event = api.getEvent();

        if (event instanceof GameRoomInitEvent) {
            let room = this.rooms.get(event.roomId);
            let runSound = !room;

            if (!room) {
                room = new GameRoom({ id: event.roomId });
                this.rooms.set(event.roomId, room);
            }

            room.initRoom(api, event);

            for (let playerId of room.players.keys()) {
                this.playerToRoom.set(playerId, room);
            }

            if (runSound) {
                setTimeout(() => {
                    api.playAudio('/audios/music.mp3')
                }, 3000);
            }

        } else if (event instanceof GameRoomUpdateEvent) {
            this.rooms.get(event.roomId)!.update(api, event.elapsedSec);
        } else if (event instanceof GameRoomRespawnEvent) {
            this.rooms.get(event.roomId)!.respawn(event);
        } else if (event instanceof ShipComponentShootEvent) {
            this.rooms.get(event.roomId)!.shoot(event);
        } else if (event instanceof ProjectileDestroyEvent) {
            this.rooms.get(event.roomId)!.projectileDestroyed(event);
        } else if (event instanceof CrystalDestroyEvent) {
            this.rooms.get(event.roomId)!.crystalDestroyed(event);
        } else if (event instanceof CrystalHitEvent) {
            this.rooms.get(event.roomId)!.crystalHit(event);
         } else if (event instanceof ShipComponentHitEvent) {
            this.rooms.get(event.roomId)!.shipComponentHit(event);
        }
    }

    requirePlayerAndRoom(playerId: string): [Ship, GameRoom] {
        let room = this.playerToRoom.get(playerId);

        if (!room) {
            throw new Error();
        }

        let player = room.players.get(playerId);

        if (!player) {
            throw new Error();
        }

        return [player, room];
    }

    render(view: View): void {
        let room = this.playerToRoom.get(view.ui.getPlayerId() ?? '');

        if (room) {
            view.renderChild(room);
        }
    }

    async runInteraction(api: ModuleApi): Promise<void> {
        let resetObject = {};

        await api.waitForUserInput({
            selectableObjects: [resetObject],
            keyboardShortcuts: { 'Shift_KeyR': resetObject },
        });

        await api.waitForServerResponse();

        let room = this.getGlobalRoom()!;

        room.reset();
        api.emitEvent({
            event: room.getInitEvent(),
        });
    }
}
globalThis.ALL_FUNCTIONS.push(WorldModule);