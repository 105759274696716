import { View } from 'outpost';
import { SnuModule } from './snu-module.ts';
import { TextComponent } from '../text/text-component.ts';
import { ALIEN_COLOR, REQUIRED_CRYSTAL_COUNT_TO_WIN, SYNERGY_ALIEN_BASE, SYNERGY_HUMAN_BASE, SYN_TIER_FACTOR } from '../constants.ts';
import { Ship } from '../ship/ship.ts';
import { SHIP_COMPONENT_PROPERTIES } from '../data-types/ship-component-properties.ts';

export function getSyn(player: Ship) {
    const humanParts = player.components.filter(c => c.kind.startsWith('human') && !c.kind.endsWith('broken'))
    const alienParts = player.components.filter(c => c.kind.startsWith('alien') && !c.kind.endsWith('broken'))

    const humanTier = Math.floor(humanParts.length / SYN_TIER_FACTOR)
    const alienTier = Math.floor(alienParts.length / SYN_TIER_FACTOR)

    const humanBoost = SYNERGY_HUMAN_BASE * (humanTier)
    const alienBoost = SYNERGY_ALIEN_BASE * (alienTier)

    const formatHumanBonus = () => [`+${humanBoost} atk HMN`, `-${humanBoost / 2} atk ALN`]
    const formatAlienBonus = () => [`+${alienBoost} atk ALN`, `-${alienBoost / 2} atk HMN`]

    return {
        humanParts,
        alienParts,
        humanTier,
        alienTier,
        formatHumanBonus,
        formatAlienBonus,
        humanBoost,
        alienBoost
    }

}

export class HudModule extends SnuModule {
    render(view: View): void {
        const playerId = view.ui.getPlayerId()

        if (!playerId) {
            return
        }

        const room = this.world.playerToRoom.get(playerId);
        const player = room?.players.get(playerId);

        if (!player) {
            return
        }

        const {
            alienParts,
            alienTier,
            formatAlienBonus,
            formatHumanBonus,
            humanParts,
            humanTier,
            alienBoost,
            humanBoost
        } = getSyn(player)

        const baseHumanAtk = SHIP_COMPONENT_PROPERTIES["human-shooter"]["damage"]
        const baseAlienAtk = SHIP_COMPONENT_PROPERTIES["alien-shooter"]["damage"]

        const screenRect = view.getRect()

        const [left, right] = screenRect.fromBottom(300,300).split('left', 150)

        const [leftLabel, leftValue] = left.split('top', 150, 16);
        view.paintChild('leftLabel', {
            ...leftLabel,
            textSize: 20,
            textVerticalAlign: 'bottom',
            textColor: 'orange',
            text: 'ATK Human',
        });

        view.paintChild('leftValue', {
            ...leftValue,
            textSize: 60,
            textVerticalAlign: 'top',
            textColor: 'orange',
            text: String(Math.floor(baseHumanAtk + humanBoost - alienBoost / 2)),
        });

        const [rightLabel, rightValue] = right.split('top', 150, 16)
        view.paintChild('rightLabel', {
            ...rightLabel,
            textSize: 20,
            textVerticalAlign: 'bottom',
            textColor: '#e57c7d',
            text: 'ATK Alien',
        });

        view.paintChild('rightValue', {
            ...rightValue,
            textSize: 60,
            textVerticalAlign: 'top',
            textColor: '#e57c7d',
            text: String(baseAlienAtk + alienBoost - humanBoost / 2),
        });

        view.layout().topRightToBottom().childHeight(32).innerMargin(8).outerMargin(16).push([
            new TextComponent({ text: `[Z] to move forward`, color: 'grey', textHorizontalAlign: 'right', textSize: 24 }),
            new TextComponent({ text: `[S] to move backward`, color: 'grey', textHorizontalAlign: 'right', textSize: 24}),
            new TextComponent({ text: `[Mouse] to rotate the ship`, color: 'grey', textHorizontalAlign: 'right', textSize: 24 }),
            new TextComponent({ text: `[Space] to salvage parts`, color: 'grey', textHorizontalAlign: 'right', textSize: 24 }),
        ])

        view.layout().bottomRightToLeft().childHeight(32).margin(16).push(new TextComponent({ text: "coord (" + Math.round(player.position.x / 100) + ", " + Math.round(player.position.y / 100) + ")", textSize: 18,color: "grey"}))

        view.layout().topLeftToBottom().childHeight(32).innerMargin(8).outerMargin(16).push([
            new TextComponent({ text: `CRYSTAL ${player.crystalCount}/${REQUIRED_CRYSTAL_COUNT_TO_WIN}`, color: 'lightblue', textHorizontalAlign: 'left',  }),
        ])

        view.layout().centerToBottom().childHeight(32).innerMargin(8).outerMargin(16).push([
            // TODO: Move left to bottom.
            new TextComponent({ text: `HUMAN ${humanParts.length}/${SYN_TIER_FACTOR * (humanTier + 1)}`, color: 'orange', textHorizontalAlign: 'left',  }),
            humanTier > 0 ? new TextComponent({ text: `${formatHumanBonus()[0]}`, color: 'green', textHorizontalAlign: 'left', textSize: 16 }) : null,
            humanTier > 0 ? new TextComponent({ text: `${formatHumanBonus()[1]}`, color: 'red', textHorizontalAlign: 'left', textSize: 16 }) : null,
            new TextComponent({ text: `ALIEN ${alienParts.length}/${SYN_TIER_FACTOR * (alienTier + 1)}`, color: ALIEN_COLOR, textHorizontalAlign: 'left', }),
            alienTier > 0 ? new TextComponent({ text: `${formatAlienBonus()[0]}`, color: 'green', textHorizontalAlign: 'left', textSize: 16 }) : null,
            alienTier > 0 ? new TextComponent({ text: `${formatAlienBonus()[1]}`, color: 'red', textHorizontalAlign: 'left', textSize: 16 }) : null,
        ])

        const isGameOver = this.game.world.getGlobalRoom()?.roomState === 'GAME_OVER'

        if (isGameOver) {
            const isWin = [...this.game.world.getGlobalRoom()!.players.values()].sort((a,b) => b.crystalCount - a.crystalCount)[0].playerId === playerId
            view.layout().topToBottom().childHeight(64).outerMargin(8).push([
                isWin ? new TextComponent({ text: 'VICTORY', color: 'yellow',  textSize: 64 }) :  new TextComponent({ text: 'DEFEAT', color: 'red',  textSize: 64 }),
            ])
        } else {
            view.layout().topToBottom().childHeight(32).outerMargin(16).innerMargin(8).push([
                new TextComponent({ text: `Parts ${room?.floatingComponents.length} ` + `Crystals ${room?.crystals.length}`, color: 'grey', textSize: 16}),
                new TextComponent({ text: 'respawn in', color: 'grey',  textSize: 24 })])
                .push( new TextComponent({ text: "" + Math.floor(room!.respawnTimerSec), color: 'white' })).height(64)
        }
    }
}
globalThis.ALL_FUNCTIONS.push(HudModule);