import { ColorLike, HorizontalAlign, KeyCombination, ModuleApi, Properties, Renderable, View } from "outpost";
// import { ShipDirection } from "../ship/ship-direction.ts";
import { TextComponent } from "../text/text-component.ts";
import { SnuModule } from '../modules/snu-module.ts';

export const MENU_SCENE_CONTROLS: Partial<{
  [Key in KeyCombination]: { endGame: "WIN" | "LOOSE" };
}> = {
  KeyP: { endGame: "WIN" },
  KeyO: { endGame: "LOOSE" },
};

class TextLineComponent implements Renderable {
    texts: string[] = []
    color: ColorLike = 'white'
    textHorizontalAlign: HorizontalAlign = 'left'

    constructor(params: Partial<Properties<TextLineComponent>>) {
        Object.assign(this, params);
    }

    render(view: View): void {
        view.layout().topLeftToRight().innerMargin(8).childHeight(24).push(
            this.texts.map(text => new TextComponent({
                text,
                textSize: text.length > 10 ? 16 : 18,
                color: this.color,
                textHorizontalAlign: this.textHorizontalAlign ?? 'left',
            })))
    }
}

const interactions = {
    'Tab': { visible: true },
    'Tab_Up': {
        visible: false
    }
}

export class LeaderboardScene extends SnuModule {
    visible: boolean = false

    async runInteraction(api: ModuleApi) {
        const { visible } = await api.waitForUserInput({
          selectableObjects: Object.values(interactions),
          keyboardShortcuts: interactions,
        });

        this.visible = visible
    }

    render(view: View): void {
        let room = this.game.world.getGlobalRoom();
        if (!room || room.roomState === 'PLAY' && !this.visible) {
            return
        }

        view.paint({
            width: 1200,
            height: 600,
            backgroundColor: '#254c84',
            backgroundAlpha: 0.8,
        });

        const headers = ['Player', 'Crystal points', 'Crystal destroyed', 'Parts salvaged'];
       
        view.layout()
            .outerMargin(16)
            .innerMargin(8)
            .topLeftToBottom()
            .childHeight(24)
            .push().leftToRight().childHeight(24).push(
                headers.map(text => new TextComponent({
                    text,
                    color: 'grey',
                    textSize: text.length > 10 ? 16 : 18,
                    textHorizontalAlign: 'left',
                }))
        ).back().childHeight(24).push(
            [...room.players.values()].map(p => new TextLineComponent({
                texts: [p.playerName, String(p.crystalCount), String(p.crystalDestroy), String(p.components.length - 1)]
            }))
        )
    }
}

globalThis.ALL_FUNCTIONS.push(TextLineComponent);
globalThis.ALL_FUNCTIONS.push(LeaderboardScene);