import { ModuleApi, Point, Renderable,  Vector, View, } from 'outpost';
import { GameEvent } from '../utils/game-event.ts';
import { Crystal } from './crystal.ts';
import { TextComponent } from '../text/text-component.ts';

export class CrystalHpBar implements Renderable {
    crystal: Crystal | null = null

    constructor(crystal: Crystal) {
        this.crystal = crystal;
    }

    render(view: View): void {

        let textRect = view.ui.getView(this.crystal!).getRect()
            .getTopNeighbor(20, this.crystal!.radius * 2 / 3)
            .scaleWidth(2)

        view.paint({
            ...textRect,
            text: this.crystal!.hp + '/' + this.crystal!.hpMax,
            textColor: 'white',
        });
    }

    update(api: ModuleApi, elapsedSecs: number) {
    }
}
globalThis.ALL_FUNCTIONS.push(CrystalHpBar);