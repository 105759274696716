import { Point, Properties, Renderable, View } from 'outpost';
import { SHIP_COMPONENT_SIZE, SHIP_COMPONENT_SLOT_SIZE } from '../constants.ts';
import { SHIP_COMPONENT_PROPERTIES, ShipComponentKind } from '../data-types/ship-component-properties.ts';
import { ShipComponent } from './ship-component.ts';

export type ShipComponentSlotKind = typeof SHIP_COMPONENT_PROPERTIES[ShipComponentKind]['slot-front' | 'slot-back' | 'slot-left' | 'slot-right']

export const SHIP_COMPONENT_SLOT_ANCHORS = <const>['front', 'back', 'right', 'left'];
export type ShipComponentSlotAnchor = typeof SHIP_COMPONENT_SLOT_ANCHORS[number];

export const SLOT_PREFIX = `slot-`

export class ShipComponentSlot implements Renderable {
    kind: ShipComponentSlotKind;
    anchor: ShipComponentSlotAnchor;
    plugged: ShipComponent | null;
    parent: ShipComponent;
    scaleForward: number = 1;

    constructor(params: Omit<Properties<ShipComponentSlot>, 'scaleForward' | 'angle'>) {
        this.kind = params.kind;
        this.anchor = params.anchor;
        this.plugged = params.plugged;
        this.parent = params.parent;
    }

    update(shootTimer: number, shootDuration: number) {
        if (shootDuration === -1) {
            return
        }

        this.scaleForward = shootTimer * 0.5 + 0.5;
    }

    get angle() {
        return this.parent.angle + SLOT_TO_ANGLE[this.anchor];
    }

    render(view: View): void {
        let angle = this.parent.angle + SLOT_TO_ANGLE[this.anchor];
        let { x, y } = this.parent.position.add([SHIP_COMPONENT_SIZE / 2, 0]).rotate(angle, this.parent.position);
        let width = SHIP_COMPONENT_SLOT_SIZE;
        let height = SHIP_COMPONENT_SLOT_SIZE;
        let backgroundColor = undefined // Color.fromStringHash(this.kind);
        let text: string | undefined = this.anchor.substring(0, 1).toUpperCase()
        text = undefined;

        if (this.kind === 'shooter') {
            let imageUrl = '/sprites/human_gun.png';
            if (this.parent.kind === 'alien-shooter') {
                imageUrl = '/sprites/alien_gun.png'
            }
            view.paint({
                x: x,
                y: y,
                width: width,
                height: height,
                angle: angle,
                imageUrl: imageUrl,
                imageScale: this.scaleForward,
            })
        }
    }

    getPosition(): Point {
        let angle = this.parent.angle + SLOT_TO_ANGLE[this.anchor];
        return this.parent.position.add([SHIP_COMPONENT_SIZE / 2, 0]).rotate(angle, this.parent.position);
    }

    canBePlugged(): boolean {
        return !this.plugged && this.kind === 'plug';
    }
};

export const SLOT_TO_ANGLE: { [Key in ShipComponentSlotAnchor]: number } = {
    front: 0,
    right: Math.PI / 2,
    back: Math.PI,
    left: 3 * Math.PI / 2,
};
globalThis.ALL_FUNCTIONS.push(ShipComponentSlot);