import { Color, ModuleApi, Point, Rect, Renderable, Rng, View } from 'outpost';
import { Ship } from './ship.ts';
import { SHIP_COMPONENT_SIZE, SYN_TIER_FACTOR, rng } from '../constants.ts';
import { SHIP_COMPONENT_SLOT_ANCHORS, SLOT_PREFIX, ShipComponentSlot, ShipComponentSlotAnchor, ShipComponentSlotKind } from './ship-component-slot.ts';
import { SHIP_COMPONENT_PROPERTIES, ShipComponentKind } from '../data-types/ship-component-properties.ts';
import { GameEvent } from '../utils/game-event.ts';
import { GameRoom } from '../game-room.ts';
import { getSyn } from '../modules/hud-module.ts';

export const ShipComponentShootEvent = GameEvent(class ShipComponentShootEvent {
    roomId: string = '';
    position: Point = Point.zero()
    playerId: string = ''
    id: string = ''
    angle: number = 0
    damage: number = 0
});

export const ShipComponentHitEvent = GameEvent(class CrystalHitEvent {
    roomId: string = '';
    id: string = '';
    damage: number = 0
    projectileId: string = '';
})

export type ShipComponentParams = {
    id: string;
    kind: ShipComponentKind;
    position?: Point;
    ship?: Ship | null;
    hp?: number;
};

export const makeShooterParams = (prefix: 'alien' | 'human') => (params: Omit<ShipComponentParams, 'hp' | 'kind'>): ShipComponentParams => ({
    ...params,
    kind: prefix + '-shooter' as ShipComponentKind
})

export const makeShieldParams = (prefix: 'alien' | 'human') => (params: Omit<ShipComponentParams, 'hp'  | 'kind'>): ShipComponentParams =>({
    ...params,
    kind: prefix + '-shield' as ShipComponentKind
})

export class ShipComponent implements Renderable {
    id: string = '';
    kind: ShipComponentKind;
    ship: Ship | null;
    position: Point;
    angle: number = 0;
    slots: ShipComponentSlot[] = [];
    hp: number = 3;
    hpMax: number = 3;
    scale: number = 1;

    shootTimerSec: number = 0

    constructor(params: ShipComponentParams) {
        this.id = params.id;
        this.kind = params.kind;
        this.ship = params.ship ?? null;
        this.position = params.position ?? Point.zero();

        let properties = SHIP_COMPONENT_PROPERTIES[this.kind];

        this.hp = properties['health']
        this.hpMax = this.hp

        for (let anchor of SHIP_COMPONENT_SLOT_ANCHORS) {
            let slotKey: `${typeof SLOT_PREFIX}${ShipComponentSlotAnchor}` = `${SLOT_PREFIX}${anchor}`;
            let slotKind: ShipComponentSlotKind = properties[slotKey];

            this.slots.push(new ShipComponentSlot({
                anchor,
                kind: slotKind,
                plugged: null,
                parent: this,
            }));
        }
    }

    getRect(): Rect {
        let { x, y } = this.position;
        let width = SHIP_COMPONENT_SIZE;
        let height = SHIP_COMPONENT_SIZE;
        
        return new Rect(x, y, width, height);
    }

    updateShooting(api: ModuleApi, room: GameRoom, elapsedSec: number): void {
        const shootPerSecond = SHIP_COMPONENT_PROPERTIES[this.kind]['shoot-per-second'] ?? 0
        const timeFrame = shootPerSecond > 0 ? 1 / SHIP_COMPONENT_PROPERTIES[this.kind]['shoot-per-second'] : -1

        if (timeFrame === 0) {
            return
        }

        this.shootTimerSec += elapsedSec

        for (let slot of this.slots) {
            slot.update(this.shootTimerSec, timeFrame)
        }

        if (this.shootTimerSec >= timeFrame) {
            this.shootTimerSec -= timeFrame

            for (let slot of this.slots) {
                if (slot.kind === 'shooter') {

                    const {
                        alienBoost,
                        humanBoost,
                    } = getSyn(this.ship!)

                    const damage = (SHIP_COMPONENT_PROPERTIES[this.kind]['damage'] ?? 0)
                        + (this.kind === 'alien-shooter' ? alienBoost : humanBoost)
                        - (this.kind === 'human-shooter' ? alienBoost / 2 : humanBoost / 2)

                    api.emitEvent({
                        event: new ShipComponentShootEvent({
                            roomId: room.id,
                            position: slot.getPosition(),
                            playerId: this.ship!.playerId,
                            id: rng.id(),
                            angle: slot.angle,
                            damage
                        }),
                        emitToPlayers: room.getPlayerIds()
                    })
                }
            }
        }
    }

    update(api: ModuleApi, room: GameRoom, elapsedSec: number): void {
       this.updateShooting(api, room, elapsedSec)

       if (this.scale < 1) {
            this.scale += elapsedSec
        } else {
            this.scale = 1
        }
    }

    render(view: View): void {
        let rect = this.getRect();
        let angle = this.angle;
        let backgroundColor: Color | null = Color.fromStringHash(this.kind);
     
        let imageUrl = '/sprites/ship_component_human_cockpit.png'

        if (this.kind === 'core') {
            rect = rect.scaleWidth(2);
            backgroundColor = null
        } else if (this.kind === 'human-shield') {
            imageUrl = '/sprites/ship_component_human_shield.png'
            backgroundColor = null
        } else if (this.kind === 'human-shooter') {
            imageUrl = '/sprites/ship_component_human_weapon.png'
            backgroundColor = null
        }else if (this.kind === 'alien-shield') {
            imageUrl = '/sprites/ship_component_alien_shield.png'
            backgroundColor = null
        } else if (this.kind === 'alien-shooter') {
            imageUrl = '/sprites/ship_component_alien_weapon.png'
            backgroundColor = null
        } else if (this.kind === 'human-broken') {
            imageUrl = '/sprites/human_broken.png'
            backgroundColor = null
        } else if (this.kind === 'alien-broken') {
            imageUrl = '/sprites/alien_broken.png'
            backgroundColor = null
        }
       
        view.paint({
            ...rect,
            angle: angle,
            backgroundColor: backgroundColor,
            textSize: '50%',
            imageUrl: imageUrl,
            imageScale: this.scale,
        })

        for (let slot of this.slots) {
            view.renderChild(slot);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ShipComponent);