import { BaseModule, ModuleApi } from 'outpost';
import { SnuGame } from '../snu-game.ts';
import { GameEvent } from '../utils/game-event.ts';
import { SnuModule } from './snu-module.ts';

export const LogoutEvent = GameEvent(class LogoutEvent {
    playerId: string = '';
});

export class LogoutModule extends SnuModule {
    async onPlayerDisconnected(api: ModuleApi): Promise<void> {
        let [player, room] = this.world.requirePlayerAndRoom(api.playerId);

        api.emitEvent({
            event: new LogoutEvent({ playerId: api.playerId }),
            emitToPlayers: room.getPlayerIds()
        });
    }

    async onEvent(api: ModuleApi) {
        let event = api.getEvent(LogoutEvent);
        let [player, room] = this.world.requirePlayerAndRoom(event.playerId);

        room.players.delete(event.playerId);
        this.world.playerToRoom.delete(event.playerId);

        console.log(`LOGOUT: ${player.playerName}`);
    }
}
globalThis.ALL_FUNCTIONS.push(LogoutModule);