import { Color, ColorLike, DisplaySizeLike, HorizontalAlign, Properties, Renderable, View } from "outpost";

export class TextComponent implements Renderable {
    text: string = ''
    color: ColorLike = 'white'
    textHorizontalAlign: HorizontalAlign = 'center'
    textSize: DisplaySizeLike = '100%'

    constructor(params: Partial<Properties<TextComponent>>) {
        Object.assign(this, params);
    }

    render(view: View): void {
        view.paint({
            text: this.text,
            textColor: this.color,
            textHorizontalAlign: this.textHorizontalAlign,
            textSize: this.textSize,
        });
    }
}

globalThis.ALL_FUNCTIONS.push(TextComponent);