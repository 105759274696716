import { ModuleApi, Point, Renderable, View, getRandomItem, makeArray } from 'outpost';
import { CRYSTAL_PER_PLAYER, REQUIRED_CRYSTAL_COUNT_TO_WIN, RESPAWN_DURATION_SEC, SHIP_COMPONENT_PER_PLAYER, STAR_COUNT, STAR_MAX_SIZE, STAR_MIN_SIZE, WINDOW_HEIGHT, WINDOW_WIDTH, WORLD_MAP_MIN_SIZE, WORLD_MAP_SIZE_PER_PLAYER, rng } from './constants.ts';
import { Crystal, CrystalDestroyEvent, CrystalHitEvent, CrystalParams, makeBigCrystalParams, makeLittleCrystalParams, makeMediumCrystalParams } from './crystal/crystal.ts';
import { Projectile, ProjectileDestroyEvent } from './projectile/projectile.ts';
import { ShipComponent, ShipComponentHitEvent, ShipComponentParams, ShipComponentShootEvent, makeShieldParams, makeShooterParams } from './ship/ship-component.ts';
import { Ship } from './ship/ship.ts';
import { GameEvent } from './utils/game-event.ts';

export type GameRoomParams = {
    id: string;
};

export type Star = { x: number; y: number; size: number; }

export const GameRoomInitEvent = GameEvent(class GameRoomInitEvent {
    roomId: string = '';
    state: RoomState = 'PLAY';
    players: Map<string, Ship> = new Map();
    shipComponents: ShipComponent[] = [];
    crystals: Crystal[] = [];
    projectiles: Projectile[] = [];
    respawnTimer: number = RESPAWN_DURATION_SEC;
    stars: Star[] = [];
});

export const GameRoomUpdateEvent = GameEvent(class {
    roomId: string = '';
    elapsedSec: number = 0
});

export const GameRoomRespawnEvent = GameEvent(class {
    roomId: string = '';
    crystals: CrystalParams[] = []
    floatingComponents: ShipComponentParams[] = []
});

export const GameRoomGameOverEvent = GameEvent(class {
    roomId: string = '';
});

type RoomState = 'PLAY' | 'GAME_OVER'

export class GameRoom implements Renderable {
    id: string;
    players: Map<string, Ship> = new Map();
    floatingComponents: ShipComponent[] = [];
    respawnTimerSec = 3;
    projectiles: Projectile[] = [];
    crystals: Crystal[] = [];
    stars: Star[] = [];
    roomState: RoomState = 'PLAY';

    constructor(params: GameRoomParams) {
        this.id = params.id;
        this.reset();
    }

    render(view: View): void {
        let activePlayer = this.players.get(view.ui.getPlayerId() ?? '');
        let px = activePlayer?.position.x ?? 0;
        let py = activePlayer?.position.y ?? 0;

        view.paint({
            backgroundColor: '#11232D'
        });

        for (let i = 0; i < this.stars.length; ++i) {
            let star = this.stars[i];

            view.paintChild(`star-${i}`, {
                x: (((star.x - px) % WINDOW_WIDTH) + WINDOW_WIDTH) % WINDOW_WIDTH,
                y: (((star.y - py) % WINDOW_HEIGHT) + WINDOW_HEIGHT) % WINDOW_HEIGHT,
                width: star.size,
                height: star.size,
                backgroundColor: 'white',
                shape: 'circle',
            });
        }

        for (let component of this.floatingComponents) {
            view.renderChild(component);
        }

        for (let ship of this.players.values()) {
            view.renderChild(ship);
        }

        for (let projectile of this.projectiles) {
            view.renderChild(projectile);
        }

        for (let crystal of this.crystals) {
            view.renderChild(crystal);
        }

        if (activePlayer) {
            view.setTransform({
                tx: WINDOW_WIDTH / 2 - px,
                ty: WINDOW_HEIGHT / 2 - py
            });

            // view.setTransform({ tx: WINDOW_WIDTH / 2, ty: WINDOW_HEIGHT / 2 });
        }
    }

    getPlayerIds(): string[] {
        return [...this.players.keys()];
    }

    initRoom(api: ModuleApi, event: InstanceType<typeof GameRoomInitEvent>) {
        // console.log('world init', event)
        this.players = event.players;
        this.floatingComponents = event.shipComponents;
        this.respawnTimerSec = event.respawnTimer;
        this.roomState = event.state;
        this.crystals = event.crystals;
        this.projectiles = event.projectiles;
        this.stars = event.stars;
        
        this.updateTimer(api, 0)
    }

    updateTimer(api: ModuleApi, elapsedSecs: number) {
        this.respawnTimerSec -= elapsedSecs

        if (this.respawnTimerSec <= 0) {
            this.respawnTimerSec = RESPAWN_DURATION_SEC

            this.serverRespawn(api)
        }
    }

    spawnCrystals() {
        const crystalCapacity = this.players.size === 0 ? 3 : (this.players.size * CRYSTAL_PER_PLAYER)
        const amountToRespond = crystalCapacity - this.crystals.length

        const infos: CrystalParams[] = []

        for (let i = 0; i < amountToRespond; i++) {

            const worldMapSize = WORLD_MAP_SIZE_PER_PLAYER

            const params = {
                id: rng.id(),
                angle: Math.random() * 360,
                position: new Point(Math.random() * worldMapSize * 2 - worldMapSize, Math.random() * worldMapSize * 2 - worldMapSize),
            }

            const distributions = {
                little: {
                    threshold: 60,
                    make: makeLittleCrystalParams
                },
                medium: {
                    threshold: 90,
                    make: makeMediumCrystalParams
                },
                big: {
                    threshold: 100,
                    make: makeBigCrystalParams
                }
            }
    
            const rand = rng.int(1, 100)
    
            for (let [kind, distrib] of Object.entries(distributions)) {
                if (rand < distrib.threshold) {
                    infos.push(distrib.make(params));
                    break
                }
            }
        }
    
        return infos
    }

    spawnFloatingComponents(api: ModuleApi) {
        const capacity = this.players.size === 0 ? 20 : this.players.size * SHIP_COMPONENT_PER_PLAYER
        const amountToRespond = capacity - this.floatingComponents.length

        const infos: ShipComponentParams[] = []

        for (let i = 0; i < amountToRespond; i++) {

            const worldMapSize = WORLD_MAP_SIZE_PER_PLAYER

            const params = {
                id: rng.id(),
                angle: Math.random() * 360,
                position: new Point(Math.random() * worldMapSize * 2 - worldMapSize, Math.random() * worldMapSize * 2 - worldMapSize),
            }

            const prefix = Math.random() <= 0.5 ? "alien" : "human"

            const distributions = {
                little: {
                    threshold: 70,
                    make: makeShieldParams(prefix)
                },
                medium: {
                    threshold: 100,
                    make: makeShooterParams(prefix)
                }
            }
    
            const rand = rng.int(1, 100)
    
            for (let [kind, distrib] of Object.entries(distributions)) {
                if (rand < distrib.threshold) {
                    infos.push(distrib.make(params));
                    break
                }
            }
        }

        return infos
    }

    serverRespawn(api: ModuleApi) {
        if (typeof window !== 'undefined') {
            return
        }

        const crystals = this.spawnCrystals();
        const floatingComponents = this.spawnFloatingComponents(api);

        api.emitEvent({
            event: new GameRoomRespawnEvent({ roomId: this.id, crystals, floatingComponents }),
            emitToPlayers: this.getPlayerIds()
        })
    }

    update(api: ModuleApi, elapsedSecs: number) {
        if (this.roomState === 'GAME_OVER') {
            return
        }

        this.updateTimer(api, elapsedSecs)

        for (let ship of this.players.values()) {
            ship.update(api, this, elapsedSecs);
        }

        for (let projectile of this.projectiles) {
            projectile.update(api, this, elapsedSecs)
        }

        for (let crystal of this.crystals) {
            crystal.update(api, elapsedSecs)
        }

        for (let player of this.players.values()) {
            if (player.crystalCount >= REQUIRED_CRYSTAL_COUNT_TO_WIN) {
                api.emitEvent({
                    event: new GameRoomGameOverEvent({ roomId: this.id })
                })
                this.roomState = 'GAME_OVER';
            }
        }
    }

    respawn(event: InstanceType<typeof GameRoomRespawnEvent>) {
        const crystalParams = event.crystals

        for (let param of crystalParams) {
            this.crystals.push(new Crystal(param));
        }

        const floatingParams = event.floatingComponents
       
        for (let param of floatingParams) {
            this.floatingComponents.push(new ShipComponent(param));
        }
    }

    shoot(event: InstanceType<typeof ShipComponentShootEvent>) {
        this.projectiles.push(new Projectile({
            playerId: event.playerId,
            position: event.position,
            angle: event.angle,
            id: event.id,
            damage: event.damage
        }))
    }

    projectileDestroyed(event: InstanceType<typeof ProjectileDestroyEvent>) {
        const eventProjectileId = event.id
        const projectile = this.projectiles.find(p => p.id === eventProjectileId);
        this.projectiles.remove(projectile!)
    }

    crystalDestroyed(event: InstanceType<typeof CrystalDestroyEvent>) {
        const eventCrystalId = event.id
        const crystal = this.crystals.find(c => c.id === eventCrystalId);
        this.crystals.remove(crystal!);
    }

    crystalHit(event: InstanceType<typeof CrystalHitEvent>) {
        const eventCrystalId = event.id
        const crystal = this.crystals.find(c => c.id === eventCrystalId);

        if (!crystal) {
            return
        }

        const damages = Math.min(event.damage, crystal.hp)

        crystal.hp -= damages

        crystal.scale = 0.8

        if (crystal.hp === 0) {
            this.crystals.remove(crystal!)

            const eventProjectileId = event.projectileId
            const proj = this.projectiles.find(p => p.id === eventProjectileId)
            
            if (!proj) {
                return
            }

            const player = this.players.get(proj.playerId)

            if (!player) {
                return
            }
            
            player!.crystalCount += crystal.scoreValue;
            player!.crystalDestroy++;
        }
    }

    shipComponentHit(event: InstanceType<typeof ShipComponentHitEvent>) {
        const shipComponentHitId = event.id

        // TODO: Use a hash map here ?
        const shipComponent = [...this.players.values()].flatMap(p => p.components).find(c => c.id === shipComponentHitId);

        if (!shipComponent) {
            return
        }

        const damages = Math.min(event.damage, shipComponent.hp)

        shipComponent.hp -= damages

        if (shipComponent.hp === 0) {
            shipComponent.slots = []
            if (shipComponent.kind === 'core') {
                return
            }

            shipComponent.kind = shipComponent.kind.startsWith('alien') ? 'alien-broken' : 'human-broken'
        }
    }

    reset() {
        for (let player of this.players.values()) {
            this.players.set(player.playerId, new Ship({
                playerId: player.playerId,
                playerName: player.playerName
            }));
        }

        this.floatingComponents = [];
        this.respawnTimerSec = 3;
        this.projectiles = [];
        this.crystals = [];
        this.roomState = 'PLAY';
        this.stars = makeArray(STAR_COUNT, () => ({
            x: Math.random() * WINDOW_WIDTH,
            y: Math.random() * WINDOW_HEIGHT,
            size: (STAR_MAX_SIZE - STAR_MIN_SIZE) * Math.random() + STAR_MIN_SIZE
        }));
    }

    getInitEvent() {
        return new GameRoomInitEvent({
            roomId: this.id,
            state: this.roomState,
            players: this.players,
            shipComponents: this.floatingComponents,
            projectiles: this.projectiles,
            crystals: this.crystals,
            respawnTimer: this.respawnTimerSec,
            stars: this.stars
        });
    }
}
globalThis.ALL_FUNCTIONS.push(GameRoom);