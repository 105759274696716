import { Constructor } from 'outpost';

export const GAME_EVENT_CONSTRUCTORS: Constructor[] = [];

export function GameEvent<T extends object>(eventClass: new () => T): (new (properties: T) => T) {
    let constructor = class {
        constructor(properties: T) {
            Object.assign(this, properties);
        }
    };

    Object.defineProperty(constructor, 'name', { value: eventClass.name })

    GAME_EVENT_CONSTRUCTORS.push(constructor);

    return constructor as (new (properties: T) => T);
}