import { BaseModule, KeyCombination, ModuleApi } from 'outpost';
import { ShipDirection } from '../ship/ship-direction.ts';
import { GameEvent } from '../utils/game-event.ts';
import { SnuModule } from './snu-module.ts';

export const SHIP_DIRECTION_CONTROLS: Partial<{ [Key in KeyCombination]: { direction: ShipDirection} }> = {
    'KeyW': { direction: 'forward' },
    'KeyS': { direction: 'backward' },
    'KeyW_Up': { direction: 'none' },
    'KeyS_Up': { direction: 'none' },
    'ArrowUp': { direction: 'forward' },
    'ArrowDown': { direction: 'backward' },
    'ArrowUp_Up': { direction: 'none' },
    'ArrowDown_Up': { direction: 'none' },
};

export const SetDirectionEvent = GameEvent(class {
    playerId: string = '';
    direction: ShipDirection = 'none';
});

export class DirectionModule extends SnuModule {
    async runInteraction(api: ModuleApi) {
        let [player, room] = this.world.requirePlayerAndRoom(api.playerId);
        let { direction } = await api.waitForUserInput({
            selectableObjects: Object.values(SHIP_DIRECTION_CONTROLS),
            keyboardShortcuts: SHIP_DIRECTION_CONTROLS
        });

        api.waitForServerResponse();

        api.emitEvent({
            event: new SetDirectionEvent({ playerId: api.playerId, direction }),
            emitToPlayers: room.getPlayerIds(),
        });
    }

    async onEvent(api: ModuleApi) {
        let event = api.getEvent(SetDirectionEvent);
        let [player, room] = this.world.requirePlayerAndRoom(event.playerId);

        player.setDirection(event.direction);
    }
};
globalThis.ALL_FUNCTIONS.push(DirectionModule);