import { ModuleApi, angleDif, sleep } from 'outpost';
import { SnuModule } from './snu-module.ts';
import { WINDOW_HEIGHT, WINDOW_WIDTH } from '../constants.ts';
import { ShipRotation } from '../ship/ship-rotation.ts';
import { GameEvent } from '../utils/game-event.ts';

export const SetRotationEvent = GameEvent(class {
    playerId: string = '';
    rotation: ShipRotation = 'none';
    targetAngle: number = 0;
});

export class RotationModule extends SnuModule {
    async runInteraction(api: ModuleApi): Promise<void> {
        if (typeof window !== 'undefined') {
            await api.sleepOnClient(30);
        }

        let [player, room] = this.world.requirePlayerAndRoom(api.playerId);
        let { pointerPosition, isPressed } = await api.waitForClientData(ui => ({
            pointerPosition: ui.getPointerPosition(),
            // isPressed: ui.isMouseButtonPressed('right')
            isPressed: true
        }));

        if (!isPressed) {
            return;
        }

        let worldPointerPosition = pointerPosition.add(player.position).sub([WINDOW_WIDTH / 2, WINDOW_HEIGHT / 2])
        let targetAngle = player.position.getAngleTo(worldPointerPosition);
        let angleDelta = angleDif(player.angle, targetAngle);
        let rotation: ShipRotation = 'none';

        if (angleDelta > 0.1) {
            rotation = 'right';
        } else if (angleDelta < -0.1) {
            rotation = 'left';
        }

        if (rotation === player.rotation) {
            return;
        }

        api.waitForServerResponse();

        api.emitEvent({
            event: new SetRotationEvent({ playerId: api.playerId, rotation, targetAngle }),
            emitToPlayers: room.getPlayerIds()
        });
    }

    async onEvent(api: ModuleApi) {
        let event = api.getEvent(SetRotationEvent);
        let [player, room] = this.world.requirePlayerAndRoom(event.playerId);

        player.setRotation(event.rotation, event.targetAngle);
    }
}
globalThis.ALL_FUNCTIONS.push(RotationModule);