import { ModuleApi } from 'outpost';
import { Ship } from '../ship/ship.ts';
import { GameEvent } from '../utils/game-event.ts';
import { SnuModule } from './snu-module.ts';

export const LoginEvent = GameEvent(class LoginEvent {
    playerId: string = '';
    playerName: string = '';
});

export class LoginModule extends SnuModule {
    loggedPlayers: Set<string> = new Set();

    async runInteraction(api: ModuleApi) {
        if (this.loggedPlayers.has(api.playerId)) {
            return;
        }

        this.loggedPlayers.add(api.playerId);

        let playerName = api.prompt('Enter your name:', 'name') ?? 'Player';

        await api.waitForServerResponse();

        api.emitEvent({
            event: new LoginEvent({ playerName, playerId: api.playerId }),
            emitToPlayers: 'all'
        });
    }

    async onEvent(api: ModuleApi) {
        let event = api.getEvent(LoginEvent);

        this.world.getGlobalRoom()!.players.set(event.playerId, new Ship({
            playerName: event.playerName,
            playerId: event.playerId,
        }));

        this.world.playerToRoom.set(event.playerId, this.world.getGlobalRoom()!);

        console.log(`LOGIN: ${event.playerName}`);
    }
}
globalThis.ALL_FUNCTIONS.push(LoginModule);