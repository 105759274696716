export type ShipComponentKind = 'core' | 'alien-shooter' | 'alien-shield' | 'human-shooter' | 'human-shield' | 'human-broken' | 'alien-broken';
export const SHIP_COMPONENT_PROPERTIES = {
    'core': {
        'name': "core",
        'health': 10,
        'damage': 0,
        'shoot-per-second': 0,
        'tribe': "none",
        'slot-front': "cockpit",
        'slot-back': "engine",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'alien-shooter': {
        'name': "alien-shooter",
        'health': 10,
        'damage': 2,
        'shoot-per-second': 0.7,
        'tribe': "alien",
        'slot-front': "shooter",
        'slot-back': "shooter",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'alien-shield': {
        'name': "alien-shield",
        'health': 10,
        'damage': 0,
        'shoot-per-second': 0,
        'tribe': "alien",
        'slot-front': "plug",
        'slot-back': "plug",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'human-shooter': {
        'name': "human-shooter",
        'health': 10,
        'damage': 2,
        'shoot-per-second': 1,
        'tribe': "human",
        'slot-front': "shooter",
        'slot-back': "plug",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'human-shield': {
        'name': "human-shield",
        'health': 10,
        'damage': 0,
        'shoot-per-second': 0,
        'tribe': "human",
        'slot-front': "plug",
        'slot-back': "plug",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'human-broken': {
        'name': "human-broken",
        'health': 0,
        'damage': 0,
        'shoot-per-second': 0,
        'tribe': "none",
        'slot-front': "plug",
        'slot-back': "plug",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
    'alien-broken': {
        'name': "alien-broken",
        'health': 0,
        'damage': 0,
        'shoot-per-second': 0,
        'tribe': "none",
        'slot-front': "plug",
        'slot-back': "plug",
        'slot-right': "plug",
        'slot-left': "plug",
        'sprite': null,

    },
}