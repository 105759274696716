import { Rng } from "outpost";

export const WINDOW_WIDTH = 1600;
export const WINDOW_HEIGHT = 900;
export const PI_2 = Math.PI * 2;

export const SHIP_COMPONENT_SIZE = 40;
export const SHIP_COMPONENT_SLOT_SIZE = 30;
export const MAX_PLUG_DISTANCE = 250;

export const WORLD_MAP_SIZE_PER_PLAYER = 1500;
export const WORLD_MAP_MIN_SIZE = 3000;

export const CRYSTAL_PER_PLAYER = 3;
export const SHIP_COMPONENT_PER_PLAYER = 10;

export const RESPAWN_DURATION_SEC = 45;
export const REQUIRED_CRYSTAL_COUNT_TO_WIN = 20;

export const rng = new Rng("snu");
export const GLOBAL_ROOM_ID = 'global-room';

export const SYN_TIER_FACTOR = 4
export const SYNERGY_HUMAN_BASE = 4
export const SYNERGY_ALIEN_BASE = 2

export const STAR_COUNT = 30;
export const STAR_MIN_SIZE = 3;
export const STAR_MAX_SIZE = 6;
export const ALIEN_COLOR = "#e57c7d"
