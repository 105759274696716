import { ModuleApi, selectClosestItem } from 'outpost';
import { SnuModule } from './snu-module.ts';
import { MAX_PLUG_DISTANCE } from '../constants.ts';
import { GameEvent } from '../utils/game-event.ts';
import { PlaySoundEvent } from './sound-module.ts';

export const PlugEvent = GameEvent(class {
    playerId: string = '';
    floatingComponentIndex: number = -1;
});

export class PlugModule extends SnuModule {
    async runInteraction(api: ModuleApi): Promise<void> {
        let [player, room] = this.world.requirePlayerAndRoom(api.playerId);
        let plugAction = {};

        await api.waitForUserInput({
            selectableObjects: [plugAction],
            keyboardShortcuts: {
                'Space': plugAction
            }
        });

        await api.waitForServerResponse();

        let closestComponent = selectClosestItem(room.floatingComponents, component => component.position.getDistanceTo(player.position));
        let canPlug = closestComponent && closestComponent.position.getDistanceTo(player.position) < MAX_PLUG_DISTANCE;

        if (canPlug) {
            api.emitEvent({
                event: new PlugEvent({ playerId: api.playerId, floatingComponentIndex: room.floatingComponents.indexOf(closestComponent!) }),
                emitToPlayers: room.getPlayerIds()
            });
        }
    }

    async onEvent(api: ModuleApi): Promise<void> {
        let event = api.getEvent(PlugEvent);
        let [player, room] = this.world.requirePlayerAndRoom(event.playerId);
        let component = room.floatingComponents[event.floatingComponentIndex];

        if (player.plugComponent(component)) {
            room.floatingComponents.remove(component);

            api.emitEvent({
                event: new PlaySoundEvent({
                    position: component.position,
                    soundId: 'PLUG'
                }),
                emitToServer: false,
                emitToPlayers: [player.playerId]
            })
        }
    }
}
globalThis.ALL_FUNCTIONS.push(PlugModule);