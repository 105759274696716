import { ModuleApi, Point, View } from 'outpost';
import { SnuModule } from './snu-module.ts';
import { GameEvent } from '../utils/game-event.ts';

type Sounds = 'BEEP' | 'MUSIC' | 'CRYSTAL_HIT' | 'PART_HIT' | 'PLUG'

const SOUND_DICT: Record<Sounds, string> = {
    'BEEP': "/audios/weapon_shoot.wav",
    'MUSIC': "/audios/music.mp3",
    'CRYSTAL_HIT': "/audios/crystal_hit.wav",
    'PART_HIT': "/audios/part_hit.wav",
    'PLUG': '/audios/plug.wav'
}
const SFX_GROUP = ['BEEP']

export const PlaySoundEvent = GameEvent(class {
    soundId: Sounds = 'BEEP'
    position: Point = Point.zero()
})

export class SoundModule extends SnuModule {
    sfxVolume = 0.3
    musicVolume = 0.5

    async onEvent(api: ModuleApi) {
        const event = api.getEvent(PlaySoundEvent)

        const audioClip = new Audio(SOUND_DICT[event.soundId])

        audioClip.volume = event.soundId === 'MUSIC' ? this.musicVolume : this.sfxVolume

        if (event.soundId === 'PLUG') {
            audioClip.volume = 1
        }

        api.playAudio(audioClip)
    }
};
globalThis.ALL_FUNCTIONS.push(SoundModule);