import { ModuleApi, Point,  Renderable, Vector, View, } from 'outpost';
import { GameEvent } from '../utils/game-event.ts';
import { CrystalHitEvent } from '../crystal/crystal.ts';
import { GameRoom } from '../game-room.ts';
import { SHIP_COMPONENT_SIZE } from '../constants.ts';
import { ShipComponentHitEvent } from '../ship/ship-component.ts';
import { PlaySoundEvent } from '../modules/sound-module.ts';

export const ProjectileDestroyEvent = GameEvent(class ProjectileDestroyEvent {
    roomId: string = '';
    id: string = '';
})

export type ProjectileParams = {
    playerId: string;
    position: Point;
    angle: number;
    id: string;
    damage: number;
}

export class Projectile implements Renderable {
    id: string = ''
    playerId: string;
    position: Point = Point.zero();
    angle: number = 0;
    movementSpeed: number = 500;
    lifetimeSec: number = 2
    damage: number = 1
    isDead = false
    isInit = false

    constructor(params: ProjectileParams) {
        this.playerId = params.playerId;
        this.position = params.position;
        this.angle = params.angle;
        this.id = params.id;
        this.damage = params.damage;
    }

    render(view: View): void {
        view.paint({
            ...this.position,
            backgroundColor: 'red',
            width: 10,
            height: 10,
            borderRadius: '50%',
        });
    }

    update(api: ModuleApi, room: GameRoom, elapsedSecs: number) {
        if (!this.isInit) {
            api.emitEvent({
                event: new PlaySoundEvent({
                    soundId: 'BEEP',
                    position: this.position
                }),
                emitToServer: false,
                emitToPlayers: [this.playerId]
            })
            this.isInit = true
        }

        if (this.isDead) {
            return
        }

        let additionalPosition = new Vector(1, 0).rotate(this.angle).mult(this.movementSpeed * elapsedSecs);
        this.position = this.position.add(additionalPosition);

        this.lifetimeSec -= elapsedSecs

        if (this.lifetimeSec <= 0) {
            api.emitEvent({
                event: new ProjectileDestroyEvent({ roomId: room.id, id: this.id }),
                emitToPlayers: room.getPlayerIds()
            })
        }

        const me = new Vector(this.position.x, this.position.y)

        // TODO : Only check players near the projectile.
        for (let part of [...room.players.values()]
            .flatMap(p => p.components)
            .filter(p => !p.kind.endsWith('broken'))) {
            const target = new Vector(part.position.x, part.position.y)

            if (target.sub(me).getLength() < SHIP_COMPONENT_SIZE / 2) {

                if (part.ship?.playerId !== this.playerId) {
                    this.isDead = true;
                    api.emitEvent({
                        event: new ShipComponentHitEvent({ roomId: room.id, id: part.id, damage: this.damage, projectileId: this.id }),
                        emitToPlayers: room.getPlayerIds()
                    });
                }
                
                api.emitEvent({
                    event: new PlaySoundEvent({
                        position: part.position,
                        soundId: 'PART_HIT'
                    }),
                    emitToServer: false,
                    emitToPlayers: [this.playerId]
                })

                api.emitEvent({
                    event: new ProjectileDestroyEvent({ roomId: room.id, id: this.id, }),
                    emitToPlayers: room.getPlayerIds()
                })
            }
        }

        for (let crystal of room.crystals) {
            const target = new Vector(crystal.position.x, crystal.position.y)

            if (target.sub(me).getLength() < crystal.radius) {
                this.isDead = true;

                api.emitEvent({
                    event: new CrystalHitEvent({ roomId: room.id, id: crystal.id, damage: this.damage, projectileId: this.id }),
                    emitToPlayers: room.getPlayerIds()
                });
                
                api.emitEvent({
                    event: new PlaySoundEvent({
                        position: crystal.position,
                        soundId: 'CRYSTAL_HIT'
                    }),
                    emitToServer: false,
                    emitToPlayers: [this.playerId]
                })

                api.emitEvent({
                    event: new ProjectileDestroyEvent({ roomId: room.id, id: this.id, }),
                    emitToPlayers: room.getPlayerIds()
                })
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Projectile);