import { BaseModule } from 'outpost';
import { SnuGame } from '../snu-game.ts';

export class SnuModule {
    game: SnuGame;

    constructor(game: SnuGame) {
        this.game = game;
    }

    get world() {
        return this.game.modules.world;
    }
}
globalThis.ALL_FUNCTIONS.push(SnuModule);